// CSS
import '../sass/pages/home.scss'

// === WP ALPHA ASSETS
import '@nematis/wp-alpha/assets/js/home'

const sections = document.querySelectorAll('section[data-block-style="hermet hermet-left"]')

sections.forEach((section) => {
  const hasMediaTextHomeClass = section.querySelector('div.media-text-1-home')

  if (hasMediaTextHomeClass) {
    section.classList.remove('py-9', 'py-xl-11')
  }
})
